import { Box, Button, Heading, SimpleGrid, Text, useColorModeValue, Stack } from '@chakra-ui/react'
import * as React from 'react'
import PricingCard from 'pages/home/Pricing/PricingCard'
import UnderlineLink from 'pages/home/utils/UnderlineLink'

export default function Pricing() {
  
  const onClickSignUp = () => {
    location.href = "https://app.walawong.com/auth/sign-up";
  }
  
  return (
    <Box as="section" bg={useColorModeValue('gray.50', 'gray.800')} py="24" id='pricing'>
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Box textAlign={{ base: 'center', lg: 'start' }}>
        <Stack
            spacing="10"
            direction={{ base: 'column', lg: 'row' }}
            align={{ base: 'flex-start', lg: 'center' }}
            justify="space-between"
          >
            <Heading
              size="3xl"
              lineHeight="normal"
              fontWeight="extrabold"
              maxW={{ base: 'unset', lg: '800px' }}
              color={useColorModeValue('gray.900', 'gray.400')}
              letterSpacing="tight"
              fontSize="6xl"
            >
              Inclusive pricing to meet your{' '}
            <UnderlineLink>
              needs.
            </UnderlineLink>
            </Heading>
            {/* <CTAButton w={{ base: 'full', md: 'auto' }}>Sign Up</CTAButton> */}
          </Stack>
          <Text
            fontSize={{ base: 'xl', lg: '2xl' }}
            mt="4"
            maxW="2xl"
            mx={{ base: 'auto', lg: 'unset' }}
          >
            Our plans are designed to be flexible catering to every stage of development,
            from small teams to enterprises.
          </Text>
        </Box>
        <SimpleGrid mt="10" columns={{ base: 1, lg: 3 }} spacing="10">
          <PricingCard
            bg="gray.100"
            color="#000000"
            button={
              <Button
                w="full"
                size="lg"
                h="16"
                backgroundColor={useColorModeValue('brand.500', 'brand.500')}
                color="#FFFFFF"
                onClick={onClickSignUp}
              >
                Sign up
              </Button>
            }
            name={'Build'}
            description={'Perfect for small team developers building a project.'}
            price={0}
            duration={'monthly'}
            benefits={[
              '3 App Deployment',
              '2 Team Members',
              'Unlimited Connections',
              '$0.50 / Connected Account',
            ]}
          />
          <PricingCard
            bg="#3C3D35"
            color="#FFFFFF"
            button={
              <Button
                w="full"
                size="lg"
                h="16"
                backgroundColor={useColorModeValue('brand.500', 'brand.500')}
                color="#FFFFFF"
                onClick={onClickSignUp}
              >
                Sign up
              </Button>
            }
            name={'Grow'}
            description={'Perfect for start-ups looking to grow product offering.'}
            price={99}
            duration={'monthly'}
            benefits={[
              '5 App Deployment',
              '5 Team Members',
              'Unlimited Connections',
              '$0.30 / Connected Account',
              '200 Free Connections / Month',
            ]}
          />
          <PricingCard
            bg="#4532CC"
            color="white"
            button={
              <Button
                w="full"
                size="lg"
                h="16"
                backgroundColor={useColorModeValue('#FFFFFF', '#FFFFFF')}
                color="black"
                _hover={{ bg: 'gray.100' }}
                onClick={onClickSignUp}
              >
                Sign up
              </Button>
            }
            name={'Scale'}
            description={'For businesses with a large user based looking to scale.'}
            price={999}
            duration={'monthly'}
            benefits={[
              'Unlimited App Deployment',
              'Unlimited Team Members',
              'Unlimited Connections',
              '$0.10 / Connected Account',
              '2000 Free Connections / Month',
            ]}
          />
        </SimpleGrid>
      </Box>
    </Box>
  )
}
import React from 'react'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
import Pricing from 'pages/home/Pricing'

function PricingPage() {
  return (
    <Layout>
      <Seo title="Home" />
      <Pricing/>
    </Layout>
  )
}

export default PricingPage
